import React from "react";
import Tag from "../Tag";
import Buynow from "./Buynow";
import Rent from "./Rent";
import * as Animate from "react-reveal";
import HeroVideo from "../../../src/assets/videos/big-energy/big-energy-video.mp4";

export default function Jumbotron({ handleShowModal, modalComponent }) {
  return (
    <>
      <main className="relative pt-8 md:pt-12 h-[80%] bg-cover bg-center bg-white">
        <div className="w-11/12 m-auto pt-12 pb-20 lg:pb-24 max-w-7xl">
          <div className="w-full m-0 md:w-full lg:w-full">
            <div className="w-full flex flex-col gap-y-8 md:flex-row justify-between items-center">
              <div className="w-full lg:w-8/12 flex flex-col justify-start lg:justify-between items-start gap-y-4   md:gap-y-8">
                <Animate.Fade bottom>
                  <Tag text="BigEnergy" />
                  <h1 className="w-full lg:w-9/12 xl:w-full font-semibold text-3xl md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight xl:pr-20 capitalize">
                    Portable &amp; Grid-like electricity for household and
                    business in energy-poor communities
                  </h1>
                </Animate.Fade>
              </div>

              <div className="w-full lg:w-5/12 relative md:mt-32 lg:mt-40">
                <Animate.Fade bottom>
                  <p className="text-cstm-grey text-base xl:text-lg leading-7 xl:leading-8 w-full lg:w-10/12">
                    BigEnergy delivers 24/7 Clean Electricity for Businesses,
                    Large Households, Schools & Healthcare Facilities in
                    energy-poor rural and Urban Communities.
                  </p>

                  <div className="flex items-center  mt-6 md:mt-4 lg:mt-6">
                    <Buynow textSize="base" handleShowModal={handleShowModal} />
                    <span className="ml-4 lg:ml-7">
                      <Rent textSize="base" />
                    </span>
                  </div>
                </Animate.Fade>
              </div>
            </div>
          </div>
        </div>
        {modalComponent}
      </main>
      <div className="">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-[50%] object-cover"
        >
          <source src={HeroVideo} type="video/mp4" />
        </video>
      </div>
    </>
  );
}
