import React, { useContext } from "react";
import useSticky from "../../hooks/useSticky";
import { App } from "../../context/appContext";
import Buynow from "./Buynow";
import Rent from "./Rent";

import BigEnergyIcon from "../../assets/images/reeddicapsule/bigenergy-icon.webp";

export default function Stickybar({ handleShowModal }) {
  const { element } = useSticky();

  return (
    <section
      ref={element}
      className="bg-[#fff] flex items-center fixed z-10 w-full top-20 transition all duration-300 ease-in-out scroll-hide"
    >
      <div className="w-11/12 m-auto my-3 max-w-7xl">
        <div className="flex justify-between items-center max-w-[600px]">
          <div className="hidden md:flex flex-row justify-between items-center gap-x-4">
            <div className="flex items-center justify-center">
              <img src={BigEnergyIcon} className="w-16 rounded-md" alt="icon" />
            </div>
            <p className="flex text-sm lg:text-sm-15">Get the BigEnergy now</p>
          </div>

          <div className="flex space-x-5 w-full sm:space-x-7 md:w-[350px] lg:space-x-8">
            <Buynow
              textSize="xs"
              width="w-5/12 md:w-[127px]"
              handleShowModal={handleShowModal}
            />
            <Rent textSize="xs" width="w-5/12 md:w-[100px]" />
          </div>
        </div>
      </div>
    </section>
  );
}
