import React, { useState } from "react";
import { Link } from "gatsby";

function BundleCard({ data }) {
  const { bundleName, image, price, benefits } = data[0];

  return (
    <div
      className="group bg-[#fff] flex-col w-1/2 p-6 items-center justify-center hidden lg:flex"
      style={{
        boxShadow: "0px 31.0344px 39.9014px rgba(224, 224, 224, 0.25)",
      }}
    >
      <div className="flex flex-col items-center px-8 pt-8 pb-12 border border-[#9CE582] rounded">
        <div className="mb-6 w-[300px]">
          <img
            src={image}
            alt={bundleName}
            className="h-auto w-full object-contain"
          />
        </div>
        <h4 className="text-base font-bold capitalize text-cstm-green text-center w-full">
          {bundleName}
        </h4>
        <p className="mt-2 font-semibold mb-6 text-[27px] text-cstm-green">
          <span>&#x20A6;</span> {price.toLocaleString()}
        </p>

        <div className="flex flex-col">
          {benefits.map((benefit) => {
            const { icon, text, isAvailable } = benefit;

            return (
              <div className="flex items-center gap-x-2 mb-3">
                <div children="ml-4">
                  <img src={icon} alt="icon" />
                </div>
                {isAvailable ? (
                  <p className="text-cstm-green font-bold capitalize text-xs">
                    {text}
                  </p>
                ) : (
                  <p className="text-[#A9A9A9] capitalize text-xs">{text}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const PreOrderNowModal = ({ handleClosePreOrder, activeBundle }) => {
  const [continueStatus, setContinueStatus] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    paymentPlan: "",
    receiveInfo: "",
    contactViaWhatsapp: "",
  });

  const handleInputData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };

  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 h-screen w-screen z-[100]">
      <button
        onClick={handleClosePreOrder}
        className="bg-white text-redditeal font-bold py-2 px-4 rounded absolute top-0 right-0 mt-5 mr-5 z-[100]"
      >
        X
      </button>
      {!continueStatus ? (
        <div
          className="flex flex-col justify-center items-center h-full w-full px-4"
          style={{
            backgroundBlendMode: "multiply",
            backgroundColor: "rgba(38 50 56 / 95%)",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-full sm:w-[310px] h-auto sm:h-[193px] bg-white p-5 text-center rounded">
            <span className="uppercase text-sm text-reedditeal font-bold block">
              Note:
            </span>
            <p className="text-sm capitalize text-[#313131] mt-5 mb-5">
              you will be charged a refundable deposit of 1% of the total order
              value
            </p>
            <button
              onClick={() => setContinueStatus(true)}
              className="py-3 px-6 block w-[130px] mx-auto text-center text-[#fff] font-medium bg-reedditeal rounded text-sm"
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div
          className="px-4 h-full w-full"
          style={{
            backgroundBlendMode: "multiply",
            backgroundColor: "rgba(38 50 56 / 95%)",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex items-stretch max-w-[1000px] mx-auto h-full py-5">
            <BundleCard data={activeBundle} />
            <div className="preorder-modal w-full md:w-[600px] md:mx-auto sm:p-7 md:p-10 lg:w-1/2 flex items-center justify-center">
              <div className="w-full sm:w-[350px] lg:w-full bg-white p-5 sm:p-7 rounded-[5px]">
                <h2 className="capitalize text-center text-xl">
                  complete your pre-order details
                </h2>

                <div className="bg-[#979797] h-[0.5px] my-5"></div>

                <form 
                action="https://formspree.io/f/xdovekpz"
                method="POST"
                >
                  <div className="flex flex-col space-y-5 sm:justify-between sm:flex-row sm:items-center sm:space-x-5 sm:space-y-0">
                    <label htmlFor="firstName">
                      <span className="block mb-2 text-xs">First Name</span>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        onChange={handleInputData}
                        className="border border-[#E0E0E0;] rounded p-2 w-full text-sm"
                        required
                      />
                    </label>

                    <label htmlFor="lastName">
                      <span className="block mb-2 text-xs">Last Name</span>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        onChange={handleInputData}
                        className="border border-[#E0E0E0] rounded p-2 w-full text-sm"
                        required
                      />
                    </label>
                  </div>

                  <div className="mt-5">
                    <label htmlFor="email" className="w-full">
                      <span className="block mb-2 text-xs">Email Address</span>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        onChange={handleInputData}
                        className="border border-[#E0E0E0] rounded p-2 w-full text-sm"
                        required
                      />
                    </label>

                    <div className="mt-2">
                      <label
                        htmlFor="receiveInfo"
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name="receiveInfo"
                          id="receiveInfo"
                          onChange={
                            () => setIsCheckboxChecked(!isCheckboxChecked)
                          }
                          value={isCheckboxChecked}
                          className="border border-[#E0E0E0] rounded p-2 text-sm"
                          checked={isCheckboxChecked}
                        />
                        <span className="text-[9px] text-[#313131] capitalize cursor-pointer">
                          Would you like to receive more information about
                          reeddi's products?
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="mt-5">
                    <label htmlFor="paymentPlan" className="w-full">
                      <span className="block mb-2 text-xs">Payment Plan</span>
                      <div className="border border-[#E0E0E0] px-1 py-[4px] rounded-[5px]">
                        <select
                          name="paymentPlan"
                          id="paymentPlan"
                          onChange={handleInputData}
                          className="p-2 w-full text-[10px] sm:text-xs border-none outline-none"
                          required
                        >
                          <option value="oneTimePayment">
                            One-Time Payment
                          </option>
                          <option value="installment">Installment</option>
                          <option value="flexPay">FlexPay</option>
                          <option value="rentOwnFlexi">RentOWn Flexi</option>
                        </select>
                      </div>
                    </label>

                    <div className="mt-2">
                      <label
                        htmlFor="terms"
                        className="flex items-center space-x-2"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1581_3841)">
                            <path
                              d="M9 5.25L9 9.75"
                              stroke="#313131"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 12.76L9.01 12.7489"
                              stroke="#313131"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                              stroke="#313131"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1581_3841">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <Link href="/faqs">
                          <span className="text-[9px] text-[#313131] capitalize underline">
                            learn more about our payment plans
                          </span>
                        </Link>
                      </label>
                    </div>
                  </div>

                  <div className="mt-5">
                    <label htmlFor="phone" className="w-full">
                      <span className="block mb-2 text-xs">Phone Number</span>
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        onChange={handleInputData}
                        className="border border-[#E0E0E0] rounded p-2 w-full text-sm"
                        required
                      />
                    </label>

                    <div className="mt-2 mb-5">
                      <label
                        htmlFor="contactViaWhatsapp"
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name="contactViaWhatsapp"
                          id="contactViaWhatsapp"
                          className="border border-[#E0E0E0] rounded p-2"
                        />
                        <span className="text-[9px] text-[#313131] capitalize cursor-pointer">
                          do you want to be contacted through whatsapp on this
                          number
                        </span>
                      </label>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="py-3 px-6 block w-full mx-auto text-center text-[#fff] font-medium bg-reedditeal rounded text-sm"
                  >
                    Continue to Payment
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreOrderNowModal;
