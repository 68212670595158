import React, { Fragment } from "react";
import {
  bigEnergy,
  bigEnergyAccessories,
  bigEnergyAccessoriesOnly,
} from "./BundleData";
import BundleCard from "./BundleCard";
import * as Animate from "react-reveal";

function BigEnergyPayment({ availability, handleShowPreOrderModal }) {
  return (
    <Fragment>
      {bigEnergy.map((data) => {
        return (
          <BundleCard
            availability={availability}
            handleShowPreOrderModal={handleShowPreOrderModal}
            key={data.bundleType}
            data={data}
          />
        );
      })}
    </Fragment>
  );
}

function BigEnergyAccessoriesPayment({
  availability,
  handleShowPreOrderModal,
}) {
  return (
    <Fragment>
      {bigEnergyAccessories.map((data) => {
        return (
          <BundleCard
            availability={availability}
            handleShowPreOrderModal={handleShowPreOrderModal}
            key={data.bundleType}
            data={data}
          />
        );
      })}
    </Fragment>
  );
}

function BigEnergyAccessoriesOnlyPayment({
  availability,
  handleShowPreOrderModal,
}) {
  return (
    <Fragment>
      {bigEnergyAccessoriesOnly.map((data) => {
        return (
          <BundleCard
            availability={availability}
            handleShowPreOrderModal={handleShowPreOrderModal}
            key={data.bundleType}
            data={data}
          />
        );
      })}
    </Fragment>
  );
}

export default function Bundles({ availability, handleShowPreOrderModal }) {
  return (
    <section className="">
      <div className="w-11/12 m-auto py-16 lg:py-24 max-w-5xl">
        <div className="flex flex-col md:gap-y-16">
          <Animate.Fade bottom>
            <h2 className="w-11/12 md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto text-center mb-8 md:mb-0  font-semibold text-3xl md:text-4xl lg:text-4xl xl:text-5xl capitalize">
              The best, simple, transparent pricing that works for you
            </h2>
            <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-8 lg:gap-9 my-4">
              <BigEnergyPayment
                availability={availability.bigEnergy}
                handleShowPreOrderModal={handleShowPreOrderModal}
              />
              <BigEnergyAccessoriesPayment
                availability={availability.bigEnergyWithAccessories}
                handleShowPreOrderModal={handleShowPreOrderModal}
              />
              {/* <BigEnergyAccessoriesOnlyPayment
                availability={availability.bigEnergyAccessoriesOnly}
                handleShowPreOrderModal={handleShowPreOrderModal}
              /> */}
            </div>
          </Animate.Fade>
        </div>
      </div>
    </section>
  );
}
