import React from 'react'
import ArrowIcon from "../../assets/images/reeddicapsule/white-arrow.svg"

export default function Buynow({ textSize, handleShowModal }) {
    return (
        <button
            onClick={() => handleShowModal()}
            className={`relative border z-20 flex justify-center items-center rounded-md py-2 lg:py-3 px-4 lg:px-6 border-2 border-cstm-green bg-cstm-green hover:shadow-lg`}
        >
            <p className={` text-sm md:text-sm-15 lg:text-${textSize} text-[#fff] pr-2`}>
                Buy BigEnergy
            </p>
            <span><img src={ArrowIcon} alt='green-arrow' /></span>
        </button>
    )
}

