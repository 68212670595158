import React from "react";
import * as Animate from "react-reveal";
import Img1 from "../../assets/images/reeddicapsule/laptop-lg-2.webp";
import Img2 from "../../assets/images/reeddicapsule/tv-lg-2.webp";
import Img3 from "../../assets/images/reeddicapsule/bulb-lg-2.webp";
import Fridge from "../../assets/images/reeddicapsule/fridge-lg-2.webp";
import Machinery from "../../assets/images/reeddicapsule/machinery.webp";

import { Parallax, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";

const PowerDevice = () => {
  const data = [
    {
      id: 1,
      image: Img1,
      title: "Laptop (<70W)",
      hours: "28+ hours",
    },
    {
      id: 2,
      image: Img2,
      title: "Tv LED (<200W)",
      hours: "15+ hours",
    },
    {
      id: 3,
      image: Img3,
      title: "15W Bulb",
      hours: "100+ hours",
    },
    {
      id: 4,
      image: Fridge,
      title: "200W Fridge",
      hours: "7+ hours",
    },
    {
      id: 5,
      image: Machinery,
      title: "200W Machinery",
      hours: "7+ hours",
    },
  ];
  return (
    <section className="py-6 lg:py-12">
      <div className="main-abstract-bg px-5 md:px-10 lg:px-16 py-6 md:py-14 lg:py-20 mb-10 md:mb-14 text-center">
        <h2 className="mb-4 text-white font-semibold md:font-semibold text-lg leading-snug md:text-3xl lg:text-5xl lg:leading-snug text-center w-full md:w-10/12 mx-auto">
          The Reeddi BigEnergy Powers Everything
          <br />
          The Reeddi Capsule Powers And More.
        </h2>
        <p className="text-base lg:text-lg text-white text-center">
          From Phone Charging to Refrigeration for a Whole day.
        </p>
      </div>

      <div className="w-11/12 m-auto mt-8 max-w-7xl">
        <div className="">
          <Animate.Fade bottom>
            <div className="">
              <Swiper
                spaceBetween={30}
                modules={[Parallax, Autoplay]}
                className="w-full pb-6"
                speed={600}
                parallax={true}
                autoplay={true}
                autoPlay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                loop={true}
                slidesPerView={4}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  640: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {data?.map((datum) => (
                  <SwiperSlide>
                    <div key={datum?.id} className="mx-auto shadow-md">
                      <div className="w-full mx-auto">
                        <img
                          src={datum?.image}
                          className="rounded-b-none"
                          alt="Image"
                        />
                      </div>
                      <div className="bg-white mx-auto text-center pt-4 pb-8 ">
                        <p className="text-sm lg:text-sm-15 font-semibold mb-1">
                          {datum?.title}
                        </p>
                        <p className="text-sm lg:text-sm-15 text-[#313131]">
                          For: {datum?.hours}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Animate.Fade>
        </div>
      </div>
    </section>
  );
};

export default PowerDevice;
