import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  bigEnergy,
  bigEnergyAccessories,
  bigEnergyAccessoriesOnly,
} from "./BundleData";
import BundleCard from "./BundleCard";

function BigEnergyPayment({
  handleMouseOver,
  handleShowPreOrderModal,
  availability,
}) {
  return (
    <div onMouseOver={() => handleMouseOver(bigEnergy[0])}>
      {bigEnergy.map((data) => {
        return (
          <BundleCard
            key={data.bundleType}
            availability={availability}
            data={data}
            handleShowPreOrderModal={handleShowPreOrderModal}
          />
        );
      })}
    </div>
  );
}

function BigEnergyAccessoriesPayment({
  handleMouseOver,
  handleShowPreOrderModal,
  availability,
}) {
  return (
    <div onMouseOver={() => handleMouseOver(bigEnergyAccessories[0])}>
      {bigEnergyAccessories.map((data) => {
        return (
          <BundleCard
            key={data.bundleType}
            availability={availability}
            data={data}
            handleShowPreOrderModal={handleShowPreOrderModal}
          />
        );
      })}
    </div>
  );
}

function BigEnergyAccessoriesOnlyPayment({
  handleMouseOver,
  handleShowPreOrderModal,
  availability,
}) {
  return (
    <div onMouseOver={() => handleMouseOver(bigEnergyAccessories[0])}>
      {bigEnergyAccessoriesOnly.map((data) => {
        return (
          <BundleCard
            key={data.bundleType}
            availability={availability}
            data={data}
            handleShowPreOrderModal={handleShowPreOrderModal}
          />
        );
      })}
    </div>
  );
}

export default function BuyNowModal({
  showBuyModal,
  handleClose,
  handleShowPreOrderModal,
  availability,
}) {
  const cancelButtonRef = useRef(null);

  const [selectedPrice, setSelectedPrice] = useState("999,000");

  const handleMouseOver = (data) => {
    setSelectedPrice(data?.price?.toLocaleString());
  };

  return (
    <Transition.Root show={showBuyModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {
          handleClose(false);
        }}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 mt-16 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* This results in some misbehavior so am commenting it out - Samad Ayoade */}
          {/* <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-center bg-white rounded-lg text-left max-w-5xl overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
              <div className="bg-[#ffffff] sm:px-6 md:px-10 py-6 md:py-10">
                <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 gap-6 my-4">
                  <BigEnergyPayment
                    availability={availability.bigEnergy}
                    handleMouseOver={handleMouseOver}
                    handleShowPreOrderModal={handleShowPreOrderModal}
                  />
                  <BigEnergyAccessoriesPayment
                    availability={availability.bigEnergyWithAccessories}
                    handleMouseOver={handleMouseOver}
                    handleShowPreOrderModal={handleShowPreOrderModal}
                  />
                  {/* <BigEnergyAccessoriesOnlyPayment
                    availability={availability.bigEnergyAccessoriesOnly}
                    handleMouseOver={handleMouseOver}
                    handleShowPreOrderModal={handleShowPreOrderModal}
                  /> */}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
