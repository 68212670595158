import React from "react";
import BigEnergyPaymentImage from "../../assets/images/bigenergy/bigEnergyPayment1.webp";
import BigEnergyAccessoriesImage from "../../assets/images/bigenergy/bigEnergyAccessories.webp";
import BigEnergyAccessoriesOnlyImage from "../../assets/images/bigenergy/bigEnergyAccessoriesOnly.webp";

import CheckIcon from "../../assets/images/reeddicapsule/green-check.svg";
import UncheckIcon from "../../assets/images/reeddicapsule/grey-uncheck.svg";

const bigEnergy = [
  {
    bundleType: (
      <>
        Reeddi BigEnergy <br />
        System
      </>
    ),
    bundleName: "Reeddi BigEnergy System",
    bundleTag: "bigEnergy",
    image: BigEnergyPaymentImage,
    link: "https://paystack.com/buy/reeddi-bigenergy-tgjwel",
    preorderLink: "https://paystack.com/buy/preorder-reeddi-bigenergy-wlogei",
    inStock: true,
    price: 1290000,
    benefits: [
      {
        icon: CheckIcon,
        text: "1 Reeddi BigEnergy",
        isAvailable: true,
      },

      {
        icon: CheckIcon,
        text: "1 Power Cord",
        isAvailable: true,
      },
      {
        icon: UncheckIcon,
        text: "1 Solar Panel",
        isAvailable: false,
      },
      {
        icon: UncheckIcon,
        text: "1 Power Cord",
        isAvailable: false,
      },
    ],
  },
];

const bigEnergyAccessories = [
  {
    bundleType: "Reeddi BigEnergy System with accessories",
    bundleName: "Reeddi BigEnergy System with accessories",
    bundleTag: "bigEnergyWithAccessories",
    image: BigEnergyAccessoriesImage,
    link: "https://paystack.com/buy/reeddi-bigenergy-with-accessories-vhkkhb",
    inStock: true,
    price: 1590000,
    preorderLink: "",
    benefits: [
      {
        icon: CheckIcon,
        text: "4 solar panels",
        isAvailable: true,
      },

      {
        icon: CheckIcon,
        text: "1 solar cable",
        isAvailable: true,
      },
      {
        icon: CheckIcon,
        text: "1 Reeddi BigEnergy",
        isAvailable: true,
      },
      {
        icon: CheckIcon,
        text: "1 Power Cord",
        isAvailable: true,
      },
    ],
  },
];

const bigEnergyAccessoriesOnly = [
  {
    bundleType: "Reeddi BigEnergy Accessories",
    bundleName: "Reeddi BigEnergy Accessories",
    bundleTag: "bigEnergyAccessories",
    image: BigEnergyAccessoriesOnlyImage,
    link: "https://paystack.com/buy/reeddi-bigenergy--accessories-tnfmjc",
    preorderLink: "",
    inStock: true,
    price: 150000,
    benefits: [
      {
        icon: CheckIcon,
        text: "1 solar panel",
        isAvailable: true,
      },

      {
        icon: CheckIcon,
        text: "1 solar cable",
        isAvailable: true,
      },
      {
        icon: UncheckIcon,
        text: "1 Reeddi BigEnergy",
        isAvailable: false,
      },
      {
        icon: UncheckIcon,
        text: "1 Power Cord",
        isAvailable: false,
      },
    ],
  },
];

export { bigEnergy, bigEnergyAccessories, bigEnergyAccessoriesOnly };
