import React from 'react'
import * as Animate from "react-reveal"
import HeroBg from "../../assets/images/big-energy-hero-2.webp"

export default function Diff() {
    return (
        <section className=''>
            <div className='w-11/12 m-auto pt-14 pb-14 lg:pt-20 lg:pb-14 max-w-7xl'>
                <div className='flex flex-col justify-center items-center'>
                    <Animate.Fade bottom>
                        <div className=' flex flex-col mb-12 md:mb-16 lg:mb-24 w-full lg:w-10/12'>
                            <h3 className='w-10/12 md:w-full mx-auto text-3xl mb-4 lg:mb-6 text-center font-semibold md:text-3xl lg:text-4xl xl:text-5xl'>
                                Built With Your Comfort In Mind.
                            </h3>
                            <p className='text-cstm-grey text-base md:text-lg md:leading-8 text-center w-full md:w-10/12 lg:w-9/12 mx-auto lg:px-3 capitalize'>
                                With an intentional focus on durability, the Reeddi BigEnergy has a 10 year life-span.
                                It is built to fit your lifestyle while meeting your daily electricity demand.
                            </p>
                        </div>
                    </Animate.Fade>
                </div>
            </div>
            <div className='w-full overflow-hidden mx-auto self-center'>
                <img
                    className='w-fit'
                    src={HeroBg}
                    alt='BigEnergy: Built with your comfort in mind'
                />
            </div>
        </section>
    )
}



