import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as Animate from "react-reveal";
import CheckIcon from "../../assets/images/reeddicapsule/check.png";

import CapsuleIconSM from "../../assets/images/reeddicapsule/capsule-quality.png";
import CapsuleIconLG from "../../assets/images/reeddicapsule/girl-with-capsule.png";

const powerMap = [
  {
    id: "1",
    icon: CheckIcon,
    title: "8+ Years",
    text: "Life-span before the system drops to 60% Capacity. Highly Durable , built to last.",
  },

  {
    id: "2",
    icon: CheckIcon,
    title: "2.4KW",
    text: "Peak Power energy generated and distributed via each BigEnergy system.",
  },

  {
    id: "3",
    icon: CheckIcon,
    title: (
      <>
        147 KgCO<sub>2</sub>
      </>
    ),
    text: "Of GHG Emission offset per day from existing fuel-based substitutes.",
  },
];

function PowerCard({ powerFeature }) {
  const { icon, title, text } = powerFeature;
  return (
    <Animate.Fade left>
      <div className="shadow-lg rounded-xl px-4 lg:px-8 py-8 flex flex-col justify-between items-start bg-white">
        <div className="flex flex-col gap-y-3">
          <h4 className="text-lg lg:text-xl font-semibold text-cstm-green">
            {title}
          </h4>
          <p className="text-base text-cstm-grey capitalize h-[4rem]">{text}</p>

          <div className="cstm-tempown-gradient-dark h-3 w-full mt-6" />
        </div>
      </div>
    </Animate.Fade>
  );
}

const mobileImg = CapsuleIconSM;
const desktopImg = CapsuleIconLG;

export default function BigEnergyCapacity() {
  const breakPoint = useBreakpoint();

  return (
    <>
      <section className="bg-[white]">
        <div className="w-11/12 m-auto py-16 lg:pt-24 lg:pb-20 max-w-7xl">
          <div className="flex flex-col gap-y-8 md:flex-row justify-between lg:justify-start items-center gap-x-16">
            <div className=" w-full flex flex-col gap-y-8">
              <div className="flex flex-col items-center gap-y-4 lg:gap-y-6">
                <Animate.Fade bottom>
                  <h3 className="text-3xl font-semibold md:text-4xl lg:text-5xl">
                    The BigEnergy Capacity
                  </h3>
                  <p className="text-left text-cstm-grey text-base">
                    Experience Endless Power with 300W Solar Panel integrated
                    with the BigEnergy System
                  </p>
                </Animate.Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main-abstract-bg">
        <div className="w-11/12 mx-auto py-16 lg:py-24 max-w-7xl">
          <h2 className="text-[#ffffff] font-bold  text-left text-3xl mb-4 md:text-4xl lg:text-5xl lg:leading-tight w-full md:w-5/12 xl:pr-8 capitalize">
            Unique <br />
            Features
          </h2>
          <div className="mt-4 h-[3px] bg-white w-full md:w-3/12 xl:pr-8" />

          <div className="mt-16 lg:mt-20">
            <Animate.Fade bottom>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-12">
                {powerMap.map((feature) => {
                  return <PowerCard key={feature.id} powerFeature={feature} />;
                })}
              </div>
            </Animate.Fade>
          </div>
        </div>
      </section>
    </>
  );
}
