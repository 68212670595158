import React from "react";
import * as Animate from "react-reveal";
import BigEnergy from "../../assets/images/bigenergy/big-energy-spec.webp";

export default function Specifications() {
  return (
    <section className="spec-section-gradient spec-big-energy">
      <div className="w-12/12 mx-auto pt-16 py-8 md:pt-14 lg:pt-20 max-w-7xl lg:flex 2xl:space-x-8">
        <Animate.Fade
          style={{
            width: "100%",
          }}
          bottom>
          <div>
            <h2 className="px-5 sm:px-8 md:px-10 xl:px-0 text-[32px] flex flex-col items-center space-y-[-10px] mb-8 text-center 2xl:text-5xl 2xl:leading-[66px] lg:items-start">
              <span className="text-reedditeal font-bold">Product</span>
              <span className="text-reeddigrey font-medium md:text-[#313131]">
                Specifications
              </span>
            </h2>

            <div className="text-sm px-5 sm:px-8 md:px-10 xl:px-5 flex items-start space-x-5 md:text-base lg:space-x-0 lg:max-w-[1100px] lg:mx-auto lg:justify-between">
              <div className="w-1/2 flex flex-col space-y-4 md:space-y-5 lg:flex-row lg:space-y-0 lg:w-auto lg:space-x-[50px]">
                <div>
                  <p className="text-reedditeal mb-1 font-bold lg:mb-[6px]">
                    AC Input
                  </p>
                  <p className="text-reeddigrey max-w-[150px] leading-8 md:text-[#313131]">
                    Max 1000W
                  </p>

                  <p className="text-reedditeal mt-4 mb-1 font-bold lg:mb-[6px]">
                    DC Input
                  </p>
                  <p className="text-reeddigrey leading-8 md:text-[#313131]">
                    Max 200W (12-30V/7A)
                  </p>
                </div>

                <div>
                  <p className="text-reedditeal mb-1 font-bold lg:mb-[6px]">
                    Warranty
                  </p>
                  <p className="text-reeddigrey md:text-[#313131]">18 Months</p>
                </div>
              </div>

              <div className="w-1/2 flex flex-col space-y-4 md:space-y-5 lg:flex-row lg:space-y-0 lg:w-auto">
                <div className="lg:order-1">
                  <p className="text-reedditeal mb-1 font-bold lg:mb-[6px]">
                    AC Output
                  </p>
                  <p className="text-reeddigrey leading-8 md:text-[#313131]">
                    <span className="font-medium">Rated Power:</span> 1800W
                  </p>
                  <p className="text-reeddigrey mb-3 leading-8 md:text-[#313131]">
                    <span className="font-medium">Peak Power:</span> 2400W
                  </p>
                  <p className="text-reedditeal mb-4 font-bold lg:mb-[6px]">
                    DC Output
                  </p>
                  <p className="text-reeddigrey leading-8 md:text-[#313131]">
                    <span className="font-medium">2* Type C Port:</span> PD 100W
                  </p>
                  <p className="text-reeddigrey leading-8 md:text-[#313131]">
                    <span className="font-medium">4* USB QC3.0:</span> Max 18W
                  </p>
                  <p className="text-reeddigrey leading-8 md:text-[#313131]">
                    <span className="font-medium">2* DC5521:</span> 13.6V/8A
                  </p>
                </div>

                <div className="hidden lg:block lg:mr-[50px]">
                  <p className="text-reedditeal mb-1 font-bold lg:mb-[6px]">
                    Battery Type
                  </p>
                  <p className="text-reeddigrey md:text-[#313131]">
                    Optimized LiFePO<sub>4</sub> Battery
                  </p>
                </div>

                <div className="lg:-order-1 lg:mr-[50px]">
                  <p className="text-reedditeal mb-1 font-bold lg:mb-[6px]">
                    Dimensions
                  </p>
                  <p className="text-reeddigrey md:text-[#313131]">
                    340 X 360 X 225 mm
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-10 lg:mt-0 lg:max-w-[900px] lg:relative lg:top-[-60px] md:flex md:justify-center">
              <img
                src={BigEnergy}
                alt="Reeddi Capsule"
                className="md:w-[60%] lgw-[70%]"
              />
            </div>
          </div>
        </Animate.Fade>
      </div>
    </section>
  );
}
