import React from 'react'
import * as Animate from "react-reveal"

const Card = ({datum}) => {
    return <div className='rounded-xl bg-white px-6 py-8 advantageCard'>
        <p className='text-cstm-green font-semibold mb-3 text-lg lg:text-xl'>{datum?.title}</p>
        <p className='text-cstm-grey text-sm-15 lg:text-base'>{datum?.subtitle}</p>
    </div>
}

const data = [
    {
        id: 1,
        title: "Fast-Charging",
        subtitle: "The BigEnergy system Charges in 2 Hours or less either with Grid Sources."
    },
    {
        id: 2,
        title: "Affordable",
        subtitle: "Our hardware-as-a-service rent to use model makes our system very affordable."
    },
    {
        id: 3,
        title: "Easy to Use",
        subtitle: "The simple interface of our energy system makes them easy to use for anyone."
    },   
    {
        id: 4,
        title: "Mobile",
        subtitle: "Could be moved anywhere by customers. Meet customers energy demands at any point in time."
    }, 
    {
        id: 5,
        title: "Data - Centered",
        subtitle: "System harvest customer’s energy information to the cloud to optimize operations."
    },  
    {
        id: 6,
        title: "Proprietary Solution",
        subtitle: "Due to the uniqueness of our solution, we have filled a US provisional patent to cover the technology."
    },      
]

const OurAdvantage = () => {
  return (
    <section className='bg-white'>
    <div className='w-11/12 m-auto py-16 lg:pt-24 lg:pb-28 max-w-7xl'>
      <div className='flex flex-col gap-y-8 md:flex-row justify-between lg:justify-start items-center gap-x-16'>
        <div className=' w-full flex flex-col gap-y-8'>
          <div className='flex flex-col items-center gap-y-4 lg:gap-y-6'>
          <Animate.Fade bottom>
            <h3 className='text-3xl font-semibold md:text-4xl lg:text-5xl'>Our Advantages</h3>
          </Animate.Fade>
          </div>

          <div className='mt-6 lg:mt-10 grid auto-cols-fr grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-12'>
            {
              data.map((datum) => {
                return (
                  <Card key={datum.id} datum={datum} />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default OurAdvantage