import React, { useContext, useEffect } from 'react'
import { App } from '../../context/appContext'
import ArrowIcon from "../../assets/images/reeddicapsule/green-arrow.svg"
import { Link } from 'gatsby'

export default function Rent({ textSize }) {
    return (
        <Link
            to='/rent-a-capsule'
            className={`relative flex justify-center items-center  py-2 lg:py-3 px-4 lg:px-6 rounded-md border border-cstm-green hover:shadow-lg `}
        >
            <p className={`text-cstm-green text-sm-15 lg:text-${textSize} text-cstm-green pr-2`}>
                Rent BigEnergy
            </p>
            <span>
                <img src={ArrowIcon} alt="green-arrow" />
            </span>
        </Link>
    );
}
