import React from 'react'
import * as Animate from "react-reveal"

import CapsuleFeatureImage from "../../assets/images/bigenergy-feature.webp"

export default function FeaturesImage() {
    return (
        <section className="">
            <div className="w-11/12 m-auto pt-12 md:pt-16 lg:pt-20 pb-20 lg:pb-24  max-w-7xl">
                <div className="">
                    <Animate.Fade bottom>
                        <div className="flex-col gap-y-4">
                            <h3 className="text-3xl font-semibold md:text-3xl lg:text-4xl xl:text-5xl text-center">
                            The BigEnergy Features<span className='text-cstm-green'>.</span>
                            </h3>
                        </div>
                        <div className="mt-10 md:mt-14 lg:mt-16 w-full">
                            <img
                                src={CapsuleFeatureImage}
                                alt="capsule-features"
                            />
                        </div>
                    </Animate.Fade>
                </div>
            </div>
        </section>
    );
}
