import React, { useState } from "react";
import Layout from "../containers/Layout";
import { Contact } from "../components";
import { Cta } from "../components/AmbassadorComponents";
import Modallayout from "../containers/Modallayout";
import Jumbotron from "../components/BigEnergyComponents/Jumbotron";
import Stickybar from "../components/BigEnergyComponents/Stickybar";
import Diff from "../components/BigEnergyComponents/Difference";
import Features from "../components/BigEnergyComponents/Features";
import PowerDevice from "../components/BigEnergyComponents/PowerDevice";
import BigEnergyCapacity from "../components/BigEnergyComponents/BigEnergyCapacity";
import OurAdvantage from "../components/AmbassadorComponents/OurAdvantage";
import FeaturesImage from "../components/BigEnergyComponents/FeaturesImage";
import Specifications from "../components/BigEnergyComponents/Specifications";
import Measures from "../components/BigEnergyComponents/MeasureComponents/Measures";
import Bundles from "../components/BigEnergyComponents/Bundles";
import BuyNowModal from "../components/BigEnergyComponents/BuyNowModal";
import PreOrderNowModal from "../components/BigEnergyComponents/PreOrderNowModal";
import {
  bigEnergy,
  bigEnergyAccessories,
  bigEnergyAccessoriesOnly,
} from "../components/BigEnergyComponents/BundleData";

export default function BigEnergy() {
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showPreOrderModal, setShowPreOrderModal] = useState(false);
  const [availability, setAvailability] = useState({
    bigEnergy: true,
    bigEnergyWithAccessories: true,
    bigEnergyAccessoriesOnly: true,
  });
  const [bundles, setBundles] = useState([
    bigEnergy,
    bigEnergyAccessories,
    bigEnergyAccessoriesOnly,
  ]);
  const [activeBundle, setActiveBundle] = useState(null);

  const handleShowModal = () => {
    setShowBuyModal(true);
  };
  const handleShowPreOrderModal = (bundleTag) => {
    setShowPreOrderModal(true);

    if (bundleTag === "bigEnergy") {
      setActiveBundle(bundles[0]);
    }

    if (bundleTag === "bigEnergyWithAccessories") {
      setActiveBundle(bundles[1]);
    }

    if (bundleTag === "bigEnergyAccessories") {
      setActiveBundle(bigEnergyAccessoriesOnly);
    }
  };

  return (
    <Layout
      title={"BigEnergy"}
      stickyComponent={<Stickybar handleShowModal={handleShowModal} />}
    >
      <Jumbotron
        handleShowModal={handleShowModal}
        modalComponent={<Modallayout />}
      />
      <Diff />
      <BigEnergyCapacity />
      <Specifications />
      <OurAdvantage />
      <PowerDevice />
      <FeaturesImage />
      <Features />
      <Measures />
      <Bundles
        availability={availability}
        handleShowPreOrderModal={handleShowPreOrderModal}
      />
      <Contact />

      {/* Buy Now Modal */}
      <BuyNowModal
        showBuyModal={showBuyModal}
        handleClose={() => setShowBuyModal(false)}
        availability={availability}
        handleShowPreOrderModal={handleShowPreOrderModal}
      />

      {/* Pre Order Now Modal */}
      {showPreOrderModal && (
        <PreOrderNowModal
          activeBundle={activeBundle}
          handleClosePreOrder={() => setShowPreOrderModal(false)}
        />
      )}
    </Layout>
  );
}
